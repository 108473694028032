import { SearchDestination, SearchOrigin } from '~/domains/search';

type ValidationErrors = string[] | undefined;

const datesError = ref<ValidationErrors>();
const destinationError = ref<ValidationErrors>();
const originError = ref<ValidationErrors>();

export const useFlightHotelValidator = () => {
  const { t } = useI18n();

  const router = useRouter();
  router.afterEach(() => resetErrors());

  const softValidateDates = (dates?: Date[]) =>
    Boolean(dates && dates.length === 2);

  const validateDates = (dates?: Date[]) => {
    if (!softValidateDates(dates)) {
      datesError.value = [t('search.filter.errors.missingDates')];

      return;
    }

    datesError.value = undefined;
  };

  const softValidateDestination = (destination?: SearchDestination) =>
    Boolean(destination?.text);

  const validateDestination = (destination?: SearchDestination) => {
    if (!softValidateDestination(destination)) {
      destinationError.value = [t('search.filter.errors.missingDestination')];

      return;
    }

    destinationError.value = undefined;
  };

  const softValidateOrigin = (origin?: SearchOrigin) => Boolean(origin?.text);

  const validateOrigin = (origin?: SearchOrigin) => {
    if (!softValidateOrigin(origin)) {
      originError.value = [t('search.filter.errors.missingOrigin')];

      return;
    }

    originError.value = undefined;
  };

  const validateFlightHotelParams = (
    dates?: Date[],
    destination?: SearchDestination,
    origin?: SearchOrigin,
  ) => {
    validateDates(dates);
    validateDestination(destination);
    validateOrigin(origin);
  };

  const resetErrors = (error?: 'dates' | 'destination' | 'origin') => {
    if (error) {
      switch (error) {
        case 'dates':
          datesError.value = undefined;
          break;
        case 'destination':
          destinationError.value = undefined;
          break;
        case 'origin':
          originError.value = undefined;
          break;
      }
    } else {
      datesError.value = undefined;
      destinationError.value = undefined;
      originError.value = undefined;
    }
  };

  return {
    datesError,
    destinationError,
    originError,
    resetErrors,
    validateDates,
    softValidateDates,
    validateOrigin,
    softValidateOrigin,
    validateDestination,
    softValidateDestination,
    validateFlightHotelParams,
  };
};
