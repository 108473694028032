import { SupportedLanguages } from '~/core';
import { BookingBarPayload } from '~/domains/booking';
import {
  SearchDestinationResultType,
  useExpediaFeatureAsyncData,
} from '~/domains/graphql';
import { SearchDestination, SearchOrigin } from '~/domains/search';
import { FORMATS, formatDate } from '~/helpers';

type TouchPoint = 'hotel' | 'search';

type Source = 'homepage' | 'hotel' | 'search' | 'searchCard';

type GenerateUrl = (
  origin: SearchOrigin,
  destination: SearchDestination,
  staysData: BookingBarPayload,
  touchPoint?: TouchPoint,
) => string;

// @see https://help.tradestation.com/10_00/eng/tsdevhelp/elobject/class_el/lcid_values.htm
const localeToTLD = {
  [SupportedLanguages.ENGLISH]: 'uk',
  [SupportedLanguages.FRENCH]: 'fr',
  [SupportedLanguages.GERMAN]: 'de',
  [SupportedLanguages.RUSSIAN]: '', // Not supported on Expedia for now
  [SupportedLanguages.TURKISH]: '', // Not supported on Expedia for now
};

const sourceToAnalytics: Record<Source, string> = {
  homepage: 'HPSEARCH',
  hotel: 'SEARCH',
  search: 'SEARCH',
  searchCard: 'RCSEARCH',
};

export const useFlightHotelUrlGenerator = (
  locale: Ref<string>,
  source: Source,
) => {
  const { data } = useExpediaFeatureAsyncData();
  const localization = useLocalizationHeader();
  const hotelIds = computed(
    () => data.value?.globalConfiguration?.expedia.expediaHotelIds ?? [],
  );

  const {
    public: { expediaSearchUrl },
  } = useRuntimeConfig();

  const generateUrl: GenerateUrl = (
    origin,
    destination,
    staysData,
    touchPoint = 'search',
  ) => {
    const page = touchPoint === 'hotel' ? 'hotel/details' : 'search';

    const checkIn = new Date(staysData.dateIn);
    const checkInDate = formatDate(checkIn, FORMATS.apiFormat);
    const checkOutDate = formatDate(
      new Date(checkIn.getTime() + staysData.nights * 24 * 60 * 60 * 1000),
      FORMATS.apiFormat,
    );

    const tld = localeToTLD[locale.value as keyof typeof localeToTLD] || 'uk';
    const url = new URL(
      `/go/package/${page}/FlightHotel/${checkInDate}/${checkOutDate}`,
      expediaSearchUrl.replace('.fr', `.${tld}`),
    );

    const roomsAndGuests = staysData.roomsAndGuests.length
      ? staysData.roomsAndGuests
      : [{ adults: 2, childrenAges: [] }];

    const { adults, childrenAges } = roomsAndGuests.reduce(
      (acc, { adults, childrenAges }) => {
        acc.adults += adults;
        acc.childrenAges.push(...childrenAges);

        return acc;
      },
      { adults: 0, childrenAges: [] },
    );
    const children = childrenAges.length;

    url.searchParams.append('cabinClass', 'p');
    // Origin and destination
    url.searchParams.append('FromAirport', origin.airportCode ?? '');
    url.searchParams.append(
      'Destination',
      destination.text.replace(/\W/g, ' '),
    );
    if (destination.type === SearchDestinationResultType.Hotel) {
      url.searchParams.append('HotelID', destination.destinationId ?? '');
    } else {
      if (hotelIds.value.length) {
        url.searchParams.append('HotelID', hotelIds.value.join(','));
      }

      url.searchParams.append('DestinationID', destination.destinationId ?? '');
    }

    // Rooms and guests
    url.searchParams.append('NumRoom', roomsAndGuests.length.toString());
    url.searchParams.append('NumAdult', adults.toString());
    if (children > 0) {
      url.searchParams.append('NumChild', children.toString());
    }
    roomsAndGuests.forEach(({ adults, childrenAges }, index) => {
      const room = `Room${index + 1}`;
      url.searchParams.append(`NumAdult-${room}`, adults.toString());
      if (childrenAges.length > 0) {
        url.searchParams.append(
          `NumChild-${room}`,
          childrenAges.length.toString(),
        );
        childrenAges.forEach((age, childIndex) => {
          const child = `Child${childIndex + 1}Age`;
          url.searchParams.append(`${room}-${child}`, age.toString());
        });
      }
    });

    // Analytics
    const analyticSource = sourceToAnalytics[source];
    const analytic =
      `ACCOR-${localization.value || tld}_${locale.value}.DPS.ACCOR.AIC${analyticSource}-SR.PACKAGE`.toUpperCase();
    url.searchParams.append('MDPCID', analytic);

    return url.toString();
  };

  return {
    generateUrl,
  };
};
